<template>
  <div class="CteManagement__Table">
    <DataTable 
      :header="ctes.data.headers" 
      :table="ctes.data.content" 
      @see="handleSee" 
      @download="handlerDownload" 
      @downloadXml="handlerDownloadXml" 
      @generateCte="handlerGenerate"
      @seeErrors="handleSeeErrors"
      @remove="handleRemove"
    />
    <Pagination @page="search()" @paginate="search()" />

    <!-- Cte Dialog -->
    <CteDialog ref="CteDialog" @generate="generate" @remove="remove" />
    
    <!-- Cte Errors -->
    <CteDetailsErrors ref="CteDetailsErrors" />
  </div>
</template>
<script>
import { ctes } from '@/api'
import { mapActions, mapState } from 'vuex'
import { DataTable, Pagination } from '@/components/molecules'
import CteDetailsErrors from './CteDetailsErrors'
import CteDialog from './CteDialog'

export default {
  name: 'CteDetailsTable',
  components: {
    DataTable,
    Pagination,
    CteDetailsErrors,
    CteDialog,
  },
  data() {
    return {
      filters: {},
    }
  },
  computed: {
    ...mapState({
      ctes: (state) => state.routes.ctes,
      page: (state) => state.pagination.page,
      paginate: (state) => state.pagination.paginate,
    }),

    //Route
    routeParams() {
      return {
        routeId: this.$route.params.route_id,
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      fetchCtes: 'routes/fetchCtes',
      setLengthItems: 'pagination/setLengthItems',
      setPagination: 'pagination/setPagination',
      setLoading: 'loading/setLoading',
    }),

    //Init
    init() {
      this.search()
    },

    //Actions
    async search() {
      const params = {
        routeId: this.routeParams.routeId,
        page: this.page,
        paginate: this.paginate,
      }
      await this.fetchCtes(params).then(() => {
        this.setPagination(this.ctes)
      })

      this.$emit('update')
    },

    //Handlers
    handleRemove(data) {
      this.$refs.CteDialog.handlerOpen()
      this.$refs.CteDialog.handleRemove(data)
    },

    handleSee(data) {
      this.$router.push(`/routes/management/cte/${this.routeParams.routeId}/edit/${data.id}`)
    },

    async handlerDownload(data) {
      this.setLoading(true)
      await ctes.downloadDacte({id: data.id}, (r) => r, (e) => e, () => this.setLoading(false))
    },

    async handlerDownloadXml(data) {
      this.setLoading(true)
      await ctes.downloadDacteXml({id: data.id}, (r) => r, (e) => e, () => this.setLoading(false))
    },

    handlerGenerate(data) {
      this.$refs.CteDialog.handlerOpen()
      this.$refs.CteDialog.handleGenerate(data)
    },

    handleSeeErrors(data) {
      this.$refs.CteDetailsErrors.open(data)
    },

    //Actions
    async generate(data) {
      this.setLoading(true)
      await ctes.generateCte(data.id, (r) => {
        this.search()
        return r
      }, (e) => e, () => this.setLoading(false))
    },

    async remove(data){
      await ctes.deleteCte({id: data.id}, (r) => {
        this.search()
        return r
      }, (e) => e, () => this.setLoading(false))
    },
  },
}
</script>
