<template>
  <main class="CteDetails">
    <!-- Sub Header -->
    <TheSubHeader
      icon="package-delivery-alt"
      title="Detalhes da CTe"
      :actions="['back']"
      organizer-gap="1rem"
    />
    <!--Content -->
    <section class="CteDetails__Container">
      <div class="CteDetails__Content">
        <div class="CteDetails__Form">
          <CteDetailsForm />
        </div>
        <div class="CteDetails__Table">
          <h3 class="CteDetails__Table--title">
            Lista de CTEs
          </h3>
          <CteDetailsTable ref="CteTable" />
        </div>
      </div>
    </section>

    <CteDialog ref="CteDialog" @remove="remove" />
  </main>
</template>
<script>
import { TheSubHeader } from '@/components/organisms'
import CteDetailsForm from './Form/CteDetailsForm.vue'
import CteDetailsTable from './CteDetailsTable.vue'
import CteDialog from './CteDialog'

export default {
  name: 'CteDetails',
  components: {
    TheSubHeader,
    CteDetailsForm,
    CteDetailsTable,
    CteDialog,
  },
  methods: {

    //Handlers
    handleRemove(data) {
      this.$refs.CteDialog.handlerOpen()
      this.$refs.CteDialog.handleRemove(data)
    },

    //Actions
    remove(data){
      this.$refs.CteTable.remove(data)
    }
  }
}
</script>
<style lang="scss" scoped>
.CteDetails {
  &__subHeaderTab {
    display: grid;
    grid-column-gap: 1rem;
    align-items: end;
    width: 100%;
  }

  &__Container{
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__Content {
    grid-area: main;
    margin-bottom: 50px;
  }

  &__Form {
    margin-bottom: 50px;
  }

  &__Table {
    &--title {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      font-size: $font-size-1xmini;
      margin-bottom: 20px;
    }
  }
}
</style>