<template>
  <Modal v-if="modal" class="CteDetailsErrors__Modal" @close="close">
    <template #header>
      <div class="CteDetailsErrors__Modal__Header">
        <span>Erro na CTe <b>{{ data.name }}</b> </span>
      </div>
    </template>
    <template #body>
      <div class="CteDetailsErrors__Modal__Body">
        {{ erro }}
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions } from 'vuex'
import { ctes } from '@/api'
import { Modal } from '@/components/molecules'

export default {
  name: 'CteDetailsErrors',
  components: {
    Modal,
  },
  data() {
    return {
      modal: false,
      data: null,
      erro: null,
    }
  },
  watch: {
    data(val) {
      if (val) {
        this.fetchErrorsCte()
      }
    },
  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),

    async fetchErrorsCte() {
      this.setLoading(true)
      await ctes.erroCteFocos({id: this.data.id}, (response) => {
        this.erro = response
        return response
      }, (error) => error, () => this.setLoading(false))
    },

    //Handlers
    open(data) {
      this.modal = true
      this.data = data
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.data = null
      this.$emit('close')
    },

  }
}
</script>
<style lang="scss" scoped>
.CteDetailsErrors {
  &__Modal {

    /deep/ .Modal__container {
      width: auto;
      height: auto;
      max-height: 90vh;
      min-width: 800px;
    }

    &__Header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      span {
        font-weight: $font-weight-bold;
        padding-left: 10px;
      }
    }

    &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 1.5rem 1.5rem;
    }

  }
}
</style>
