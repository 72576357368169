<template>
  <DynamicForm
    ref="form"
    class="CteDetails__Form__Fields"
    :model="form"
    :blueprint="blueprint()"
  />
</template>
<script>
import { mapActions } from 'vuex'
import { ctes } from '@/api'
import blueprint from './CteDetailsForm.blueprint'
import { DynamicForm } from '@/components/molecules'

export default {
  name: 'CteDetailsForm',
  components: {
    DynamicForm,
  },
  data() {
    return {
      form: {
        id: null,
        status_route: null,
      },
    }
  },
  computed: {
    routeId() {
      return this.$route.params.route_id
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),

    //Form
    blueprint,

    //Init
    init() {
      if(this.routeId) {
        this.fetchCte()
      }
    },

    async fetchCte() {
      await ctes.fetchCte({id: this.routeId}, (res) => {
        for (let i in this.form) {
          if (res[i]) this.form[i] = res[i]
        }
      })
    },
    
  }
}
</script>
<style lang="scss" scoped>
.CteDetails {
  &__Form {
    &__Fields {
      grid-template-areas:
      "labelRouteDetails labelRouteDetails . . . . . . . . . ."
      "id id id status_route status_route status_route . . . . . .";
    }
  }
}
</style>
