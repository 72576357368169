export default () => {
  return [
    {
      id: 'labelRouteDetails',
      tag: 'label',
      content: 'Detalhes da Rota'
    },
    {
      id: 'id',
      model: 'id',
      tag: 'InputGroup',
      props: {
        label: 'ID da Rota',
        placeholder: 'Nome',
        readOnly: true,
      }
    },
    {
      id: 'status_route',
      model: 'status_route',
      tag: 'InputGroup',
      props: {
        label: 'Status',
        placeholder: 'Status',
        readOnly: true,
      }
    }
  ]
}